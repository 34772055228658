[data-v-2f48d32e] .el-tag--light {
  max-width: 120px;
}
.popover[data-v-2f48d32e] {
  width: 900px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.popover .itemList[data-v-2f48d32e] {
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 160px;
}
.popover .itemList .itemList-i-t[data-v-2f48d32e] {
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.popover .itemList .itemList-i-i[data-v-2f48d32e] {
  width: 160px;
  height: 160px;
}