
.d2-container-full__body[data-v-cff44964] {
  padding: 0 !important;
}
.t[data-v-cff44964] {
  position: relative;
  padding: 20px;
  min-height: 95%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.isSlide[data-v-cff44964]{
  overflow: hidden !important;
}
