.pages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.wrrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.title-width {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 32%;
  border: 1px solid grey;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 1%;
}
.item_box .auto {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: auto;
}
.item_box .active {
  background-color: #f5f5f5;
}
.item_box .box_header {
  background-color: #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
  padding: 10px;
  font-size: 14px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.item_box .box_header ::v-deep.el-button.is-round {
  padding: 6px 13px;
}
.item_box .box_td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: bold;
  padding: 15px 20px;
  font-size: 14px;
  border-top: 1px solid #f5f5f5;
  border-bottom: 2px solid #f5f5f5;
}
.item_box .box_th:last-child {
  border: 0;
}
.box_th {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 15px 20px;
  font-size: 14px;
  border-bottom: 2px solid #f5f5f5;
  cursor: default;
  overflow-y: auto;
}
.box_th .box_icon {
  width: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-right: 10px;
}
.contents {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}