.d2-page-cover[data-v-d3f32316] {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.d2-page-cover[data-v-d3f32316] {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.d2-page-cover[data-v-d3f32316] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.d2-page-cover .d2-page-cover__logo img[data-v-d3f32316] {
  width: 200px;
}
.d2-page-cover .d2-page-cover__title[data-v-d3f32316] {
  margin: 0px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #303133;
}
.d2-page-cover .d2-page-cover__sub-title[data-v-d3f32316] {
  margin: 0px;
  margin-bottom: 5px;
  color: #606266;
}
.d2-page-cover .d2-page-cover__build-time[data-v-d3f32316] {
  margin: 0px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #C0C4CC;
}