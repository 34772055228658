[data-v-209852f6] .el-dialog {
  width: 60% !important;
  height: 700px;
}
[data-v-209852f6] .el-dialog__footer {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.dialogContent[data-v-209852f6] {
  width: 100%;
  height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}
.enclosure-box[data-v-209852f6] {
  border: 1px solid #ddd;
  margin-top: 30px;
}
.enclosure-box .enclosure-boxTop[data-v-209852f6] {
  background: #ddd;
  line-height: 30px;
  width: 100%;
  display: inline-block;
  padding: 0 10px;
}
.enclosure-box .enclosure-boxContent[data-v-209852f6] {
  padding: 10px 15px;
}