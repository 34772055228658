[data-v-514670ba] .forbidden {
  border-color: #c4616e !important;
}
[data-v-514670ba] .forbidden .el-radio__label {
  color: #c4616e !important;
}
[data-v-514670ba] .forbidden .is-checked .el-radio__inner {
  border-color: #c4616e !important;
  background: #c4616e !important;
}
[data-v-514670ba] .el-switch.is-disabled {
  opacity: 1 !important;
}
[data-v-514670ba] .el-switch__core {
  cursor: pointer !important;
}