.itemList-status[data-v-14e64b21] {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  color: red;
}
.itemList-status-left[data-v-14e64b21] {
  position: absolute;
  left: 20px;
  top: 10px;
  font-size: 14px;
  color: red;
}
.el-col-5[data-v-14e64b21] {
  width: 19%;
}
.itemList[data-v-14e64b21] {
  font-size: 16px;
}
.itemList .itemList-i[data-v-14e64b21] {
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.itemList .itemList-i .itemList-i-i[data-v-14e64b21] {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.itemList .itemList-i .itemList-i-t[data-v-14e64b21] {
  font-size: 14px;
  font-weight: 600;
  margin: 2px 0;
  color: #515a6e;
}
.itemList .itemList-i .itemList-i-d[data-v-14e64b21] {
  font-size: 14px;
  color: #808695;
}