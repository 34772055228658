.ExtraTips[data-v-78f5affd] {
  margin-top: 10px;
  color: red;
}
.component-upload-image[data-v-78f5affd] {
  width: 100%;
}
[data-v-78f5affd] .el-upload-list__item {
  background: rgba(153, 153, 153, 0.3);
}
.component-upload-image div[data-v-78f5affd] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
[data-v-78f5affd].el-upload--picture-card {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
[data-v-78f5affd].hide .el-upload--picture-card {
  display: none;
}
[data-v-78f5affd] .el-list-enter-active,[data-v-78f5affd] .el-list-leave-active {
  -webkit-transition: all 0s;
  transition: all 0s;
}
[data-v-78f5affd] .el-upload-list--picture-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
[data-v-78f5affd] .el-upload--picture-card {
  min-width: 148px;
}
[data-v-78f5affd] .el-list-enter, .el-list-leave-active[data-v-78f5affd] {
  opacity: 0;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
[data-v-78f5affd] .el-upload-list__item-thumbnail {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  line-height: 148px;
}
[data-v-78f5affd] .el-upload-list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}