.d2-badge[data-v-7e9ccabd] {
  margin-bottom: 20px;
}
.d2-badge p[data-v-7e9ccabd] {
  margin: 0px;
  margin-bottom: 2px;
}
.d2-badge p[data-v-7e9ccabd]:last-child {
  margin-bottom: 0px;
}
.d2-badge p img[data-v-7e9ccabd] {
  display: inline-block;
  margin: 0px 2px;
}