.popover[data-v-7bd60a4a] {
  width: 900px;
}
.lodingItem-inp[data-v-7bd60a4a] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  padding: 0 15px;
  cursor: pointer;
  background: #F5F7FA;
  color: #C0C4CC;
}
.margin-lef[data-v-7bd60a4a] {
  margin-left: 20px;
}