[data-v-5e335deb] .forbidden {
  border-color: #c4616e !important;
}
[data-v-5e335deb] .forbidden .el-radio__label {
  color: #c4616e !important;
}
[data-v-5e335deb] .forbidden .is-checked .el-radio__inner {
  border-color: #c4616e !important;
  background: #c4616e !important;
}
[data-v-5e335deb] .forbiddeno {
  border-color: #d40722 !important;
}
[data-v-5e335deb] .forbiddeno .el-radio__label {
  color: #d40722 !important;
}
[data-v-5e335deb] .forbiddeno .is-checked .el-radio__inner {
  border-color: #d40722 !important;
  background: #d40722 !important;
}