.dialogs[data-v-30259286] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.dialogs .dialogs-content[data-v-30259286] {
  background: #ffffff;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  padding: 20px;
}
.dialogs .dialogs-content .dialogs-header[data-v-30259286] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 20px;
  font-size: 18px;
}
.dialogs .dialogs-content .dialogs-header .el-icon-close[data-v-30259286] {
  color: #909399;
}
.dialogs .dialogs-content .dialogs_body[data-v-30259286] {
  max-width: 100%;
  padding: 0 0 20px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
.dialogs .dialogs-content .dialog_footer[data-v-30259286] {
  padding: 0 0;
  text-align: right;
}